
.custom-scrollbar {
  overflow-y: auto;
  scrollbar-color: #F39F5A #662549; 
}


.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #662549; /* Color de la barra */
  border-radius: 4px; /* Borde redondeado */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #662549; /* Color del fondo */
}



body{
  margin: 0;

}

.navbar{
  font-family: 'Bebas Neue';
}





.name {
  font-family: "Jersey 15", sans-serif;
  font-weight: 400;
  font-style: normal;

}

.name a:hover{
  color: "#FFC93C";
}

.text{
  font-family: "Agdasima", sans-serif;
}